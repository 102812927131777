/* eslint-disable */
import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DefaultLayout } from '../components/layout';

const NotFoundPage = ({ location }) => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (!isClient) return null; // Prevents SSR errors

  return (
    <DefaultLayout siteTitle="404" siteDescription="Seite konnte nicht gefunden werden." location={location}>
      <div className="error-page error-page--404">
        <div className="error__text">
          <h1>
            Seite <span className="emphasis">nicht gefunden</span>.
          </h1>
          <p>
            Wahrscheinlich haben Sie eine alte URL erwischt, die es inzwischen nicht mehr gibt. Den gesuchten Inhalt finden Sie
            vielleicht über die <Link to="/">Startseite</Link>.
          </p>
          <p>
            Sie können auch direkt mit uns <Link to="/kontakt">Kontakt aufnehmen</Link> und wir werden Ihnen gerne persönlich
            weiter helfen.
          </p>
        </div>
      </div>
    </DefaultLayout>
  );
};

NotFoundPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export default NotFoundPage;

